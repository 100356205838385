import $ from 'jquery';

import '../styles/main.scss';
import './awakeness';

// Get the button element



document.addEventListener('DOMContentLoaded', () => {
    // Your code here

  

// Get the button element and the email address
const button = document.querySelector('#copy-email-button');

// Check if the button element exists on the current page
if (button) {
  // The button element exists, so add a click event listener to it
  button.addEventListener('click', () => {
    // Your code here

const email = 'mgrebenchuk@inbox.ru'; // Replace with your email address

// Add a click event listener to the button
button.addEventListener('click', () => {
  // Create a new textarea element and set its value to the email address
  const textarea = document.createElement('textarea');
  textarea.value = email;
  textarea.style.position = 'static';
  textarea.style.opacity = 0;
  document.body.appendChild(textarea);
  
  // Select the text in the textarea and copy it to the clipboard
  textarea.select();
  document.execCommand('copy');
  
  // Remove the textarea from the DOM
  document.body.removeChild(textarea);
  
  // Show a success message
  const successMessage = document.querySelector('#success-message');
  successMessage.style.display = 'block';
});

});
}
});